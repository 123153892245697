import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";

const StyledContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginBottom: "3rem",
}));

const IncludeList = () => {
  return (
    <StyledContainer component={"article"} sx={{ background: "black" }}>
      <List
        dense
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          marginTop: "2rem",
          color: "black",
        }}
      >
        <ListItem sx={{ backgroundColor: "#A3FFD6", padding: "1.5rem" }}>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary="Access ready-to-print materials featuring QR codes."
            primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }}
          />
        </ListItem>
        <ListItem sx={{ backgroundColor: "#7BC9FF", padding: "1.5rem" }}>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary="Great social proof and more positive reviews."
            primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }}
          />
        </ListItem>
        <ListItem sx={{ backgroundColor: "#A3FFD6", padding: "1.5rem" }}>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary="Super easy review process for your guests."
            primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }}
          />
        </ListItem>
        <ListItem sx={{ backgroundColor: "#A3FFD6", padding: "1.5rem" }}>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary="Personal feedback form!"
            primaryTypographyProps={{ fontWeight: "bold", fontSize: "18px" }}
          />
        </ListItem>
      </List>
    </StyledContainer>
  );
};

export default IncludeList;
