import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Pricing from "./pages/Pricing";
import Footer from "./components/Footer";
import Faq from "./pages/Faq";
import ConnectBiz from "./components/ConnectBiz";
import PlaceId from "./components/PlaceId";
import SignUpForm from "./components/SignUpForm";
import About from "./pages/About";


// import Contact from "./components/contact";


function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<SignUpForm />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/place-locator" element={<ConnectBiz />} />
        <Route path="/placeid/:id" element={<PlaceId />} />

        <Route path="/about" element={<About />} />
        {/*<Route path="/contact" element={<Contact />} />*/}
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
