import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

const PricingCard = ({ title, price, description, includes }) => {
  return (
    <Card sx={{ padding: "1rem 2rem", width: "20rem", height: "20rem" }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          component="h3"
          sx={{ fontWeight: "bold", fontSize: "2.5rem" }}
        >
          {price}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "rgb(179,172,172)" }}>
          {description}
        </Typography>
        <Divider orientation="horizontal" flexItem />
        <List>
          {includes.map((item, index) => {
            return (
              <ListItem key={index}>
                <ListItemText
                  primary={item}
                  primaryTypographyProps={{
                    fontSize: "0.9rem",
                  }}
                />
              </ListItem>
            );
          })}
        </List>
     
      </CardContent>
    </Card>
  );
};

const Pricing = () => {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "5rem",
        margin: 0,
        background:
          "radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%)",
      }}
      component="section"
    >
      <Typography variant="h2">
        <span style={{ fontWeight: "bold" }}>Pricing</span> Plans
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
          height: "inherit",
          flexDirection: {
            lg: "row",
            md: "column",
            sm: "column",
          },
        }}
      >
        <PricingCard
          title="Silver"
          price="$9.99"
          description="One business"
          includes={[
            "Generate up to 5 custom QR codes tailored to the business's needs.",
            "Static QR Codes: Provide static QR codes that cannot be edited after creation.",
          ]}
        />
        <PricingCard
          title="Gold"
          price="$14.99"
          description="1 - 5 business accounts"
          style={{ border: "solid green" }}
          includes={[
            "Generate up to 10 custom QR codes tailored to the business's needs.",
            "Provide dynamic QR codes that can be edited or updated without changing the QR code image.",
          ]}
        />
        <PricingCard
          title="Platinum"
          price="$23.99"
          description="5+ business accounts"
          includes={[
            "Everything in Gold",
            "Dedicated customer support and assistance for businesses using the QR code generation service, with priority response times.",
          ]}
        />
      </Box>
    </Container>
  );
};

export default Pricing;
