import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 13.5rem)",
        display: "flex",
        justifyContent: "center",
        padding: "2rem",
        backgroundColor: "black",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          padding: "1",
          display: "flex",
          gap: "2rem",
          flexDirection: "column",
          backgroundColor: "black",
          color: "white",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Our Story
        </Typography>
        <Typography variant="subtitle1">
          Review Genie was born out of a shared passion for enhancing the dining
          experience and supporting the growth of business. It all started with
          a vision to revolutionize the way reviews are collected and managed,
          making it easier for restaurants to thrive in today's competitive
          market.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Our Mission
        </Typography>
        <Typography variant="subtitle1">
          At Review Genie, our mission is simple: to empower businesses to excel
          by harnessing the power of positive reviews and valuable feedback. We
          strive to provide innovative solutions that streamline the review
          process, allowing business to focus on what they do best.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Our Philisophy
        </Typography>
        <Typography variant="subtitle1">
          Our philosophy revolves around simplicity, effectiveness, and
          transparency. We believe in providing straightforward tools that make
          it effortless for patrons to leave reviews and for business to gather
          insights that drive continuous improvement. Transparency is key, as we
          aim to bridge the gap between customers and businesses while ensuring
          that feedback remains constructive and actionable. With Review Genie,
          we're committed to fostering a culture of excellence, one positive
          review at a time.
        </Typography>
      </Paper>
    </Box>
  );
};

export default About;
