import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate("/place-locator")
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 7.875rem)",
      }}
    >
      <Card sx={{ maxWidth: 387, margin: "0 auto" }}>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: "1.25rem" }}>
            Create your account
          </Typography>
          <form>
            <TextField
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              id="phone"
              label="Phone number"
              type="tel"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Typography variant="body1">
              By proceeding, you agree to our <Link href="/terms">Terms</Link>{" "}
              and <Link href="/privacy">Privacy Policy</Link>.
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: "1rem" }}
              onClick={handleSignUp}
            >
              Sign Up
            </Button>
          </form>
          <Typography variant="body1" sx={{ marginTop: "1rem" }}>
            Already have an account? <Link href="/login">Log in here</Link>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignUpForm;
