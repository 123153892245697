import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import TopHeader from "../components/TopHeader";

const faqItems = [
  {
    title: "What are the steps to get started with Review Genie?",
    content:
      "Create an account, find and confirm your restaurant, and instantly generate a stylish QR code.",
  },
  {
    title:
      "How does Review Genie help restaurants increase their Google reviews?",
    content:
      "By using Review Genie's QR codes, restaurants can easily encourage guests to leave reviews, resulting in a growth in Google reviews.",
  },
  {
    title:
      "How does Review Genie simplify the process of collecting restaurant reviews?",
    content:
      "Review Genie replaces complicated surveys and unattractive stickers with a sleek QR code design that leads directly to restaurant reviews.",
  },
  {
    title: "What is Review Genie's approach to managing feedback?",
    content:
      "Positive reviews are automatically forwarded to Google, while negative feedback is kept confidential for the restaurant's internal review, helping them improve their services.",
  },
  {
    title:
      "What is the process for guests to leave reviews using Review Genie?",
    content:
      "Guests simply scan the QR code found on business cards or stickers, which opens a web page where they can share their experience. Positive reviews are sent to Google, while constructive feedback remains private.",
  },
];

const Faq = () => {
  return (
    <>
      <Container
        component="section"
        sx={{
          height: "100vh",
          paddingTop: "2rem",
          background:
            "radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%)",
        }}
      >
        <TopHeader title="Frequently Asked Questions" />
        {faqItems.map((item, index) => (
          <Accordion key={index} sx={{ marginTop: "1rem" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              sx={{ fontWeight: "bold" }}
            >
              {item.title}
            </AccordionSummary>
            <AccordionDetails>{item.content}</AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </>
  );
};

export default Faq;
