import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import TopHeader from "./TopHeader";
import ServiceList from "./ServiceList";
import IncludeList from "./IncludeList";
import hero from "../assets/hero.webp";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleSignUpNow = () => {
    navigate("/register");
  };

  return (
    <>
      <Box
        component="section"
        height={"100vh"}
        width={"100vw"}
        display="flex"
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
          backgroundImage: `url(${hero})`,
          backgroundSize: "cover",
        }}
      >
        <Box></Box>
        <Box
          component="section"
          display="flex"
          alignItems="center"
          gap={4}
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "28rem",
            color: "white",
            textTransform: "uppercase",
            alignItems: {
              xs: "center",
              md: "center",
              lg: "baseline",
            },
            margin: {
              xs: "1rem",
              md: "1rem",
              lg: "none",
            },
          }}
        >
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              lineHeight: 0.9,
              fontSize: {
                sm: "2.5rem",
                md: "2.5rem",
                lg: "2.5rem",
              },
              textAlign: {
                sm: "center",
                md: "center",
              },
            }}
          >
            Improve your Buisness
            <span style={{ fontWeight: "normal" }}>
              by driving <em>more</em> customer
            </span>{" "}
            and sales
          </Typography>
          <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
            Sign up now to get 3 months free trial!
          </Typography>
          <Button variant="contained" size="large" onClick={handleSignUpNow}>
            Sign Up Now
          </Button>
        </Box>
      </Box>
      <Box
        component="section"
        sx={{ backgroundColor: "black", color: "white" }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <TopHeader
            title="How it works"
            subTitle="Easy setup no intergration needed"
          />
          <ServiceList />
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "black" }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "3rem",
            backgroundColor: "inherit",
            color: "white",
            height: "auto",
          }}
        >
          <TopHeader
            title="Whats included"
            subTitle="All this with Review Genie"
          />
          <IncludeList />
          <TopHeader title="Improve Guest Feedback" />
          <Typography
            variant="h6"
            sx={{ textAlign: "center", maxWidth: "35rem", margin: "1.5rem 0" }}
          >
            Sophisticated QR codes on business cards guide guests to a
            user-friendly platform where they can share their experiences.
          </Typography>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", maxWidth: "35rem", margin: "1.5rem 0" }}
          >
            Positive feedback is seamlessly forwarded to Google, while
            constructive criticism remains confidential for your internal
            review.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Home;
