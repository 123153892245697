import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ListItemText } from "@mui/material";

const StyledFooter = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: "1rem",
  textAlign: "center",
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Link to="/faq">
        <ListItemText primary="FAQ" />
      </Link>
      <Typography variant="body1">
        &copy; {new Date().getFullYear()} Review Genie
      </Typography>
    </StyledFooter>
  );
};

export default Footer;
