import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import signup from "../assets/signup.png";
import rws from "../assets/rws.png";
import ba from "../assets/ba.png";
import { Card, CardContent } from "@mui/material";

const ServiceList = () => {
  const serviceData = [
    {
      img: signup,
      title: "Create an account",
      description:
        "Just follow the steps to get it up and running in your store or business location, and you're good to go instantly.",
    },
    {
      img: ba,
      title: "QR code generation",
      description:
        "Our platform swiftly generates custom QR codes, ideal for easy printing, distribution, and seamless integration.",
    },
    {
      img: rws,
      title: "You get more reviews",
      description:
        "Effortlessly boost your business's online presence and reputation by garnering more reviews, hassle-free.",
    },
  ];

  return (
    <List
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "column",
          lg: "row",
        },
        gap: "1rem",
        margin: "4rem 0",
      }}
    >
      {serviceData.map((item, index) => (
        <ListItem key={index} sx={{ padding: "2rem", flex: 1 }}>
          <Card sx={{ maxWidth: 345, minHeight: "480px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
                padding: "1rem",
              }}
            >
              <img
                src={item.img}
                alt="sign up"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "rgb(179, 172, 172)" }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </ListItem>
      ))}
    </List>
  );
};

export default ServiceList;
