import { Card, CardContent, CircularProgress, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const PlaceId = () => {
  const [qrCodeData, setQrCodeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://qrickit-qr-code-qreator.p.rapidapi.com/api/qrickit.php?d=https://gorgeous-squirrel-0d0e70.netlify.app/?id=${id}`,
          {
            headers: {
              "X-RapidAPI-Key": process.env.REACT_APP_QR_CODE_API,
              "X-RapidAPI-Host": "qrickit-qr-code-qreator.p.rapidapi.com",
              Accept: "image/png",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const blob = await response.blob();
        console.log(blob);
        const imageUrl = URL.createObjectURL(blob);
        console.log(imageUrl);
        setQrCodeData(imageUrl);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          height: "calc(100vh - 7.875rem)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (error) {
    return (
      <div
        style={{
          height: "calc(100vh - 7.875rem)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Error: {error.message}
      </div>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "calc(100vh - 7.875rem)",
        width: "400px"
      }}
    >
      <Card>
        <CardContent>
          <img src={qrCodeData} alt="QR Code" height={150} width={150} />
          <Typography variant="h4" gutterBottom>
            Your QR Code is Ready
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Open your smartphone camera and scan the QR code to see what your
            customers will see
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PlaceId;
