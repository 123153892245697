import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  debounce,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import { useNavigate } from "react-router-dom";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
const autocompleteService = { current: null };

const ConnectBiz = () => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const loaded = useRef(false);
  const navigate = useNavigate();
  // const mapRef = useRef(null);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      console.log("here");
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  // const initializeMap = () => {
  //   mapRef.current = new window.google.maps.Map(
  //     document.getElementById("map"),
  //     {
  //       center: { lat: -34.397, lng: 150.644 },
  //       zoom: 8,
  //     }
  //   );
  // };

  // if (window.google && !mapRef.current) {
  //   initializeMap();
  // }

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handlePreview = () => {
    navigate(`/placeid/${value?.place_id}`);
  };

  return (
    <div style={{ 
      height: "calc(100vh - 7.875rem)", 
    backgroundColor: 'radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%)' }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "448px",
          justifyContent: "center",
          height: "calc(100vh - 5.875rem)",
          
        }}
      >
        {" "}
        <Card sx={{ marginBottom: "2.5rem" }}>
        <CardContent>
          <h2>Connect Business</h2>
          <Autocomplete
            id="google-map-demo"
            sx={{ marginBottom: "2.5rem" }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No results"
            renderInput={(params) => (
              <TextField {...params} label="Enter Address" fullWidth />
            )}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings || [];

              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {parts.map((part, index) => (
                        <Box
                          key={index}
                          component="span"
                          sx={{
                            fontWeight: part.highlight ? "bold" : "regular",
                          }}
                        >
                          {part.text}
                        </Box>
                      ))}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
          {/* <div id="map" style={{ height: "400px", marginTop: "1rem" }}></div> */}

          
            <Typography variant="h4" gutterBottom>
              {value?.structured_formatting.main_text}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {value?.description}
            </Typography>
            <TextField
              // label="Your Input"
              variant="outlined"
              fullWidth
              margin="normal"
              value={
                value
                  ? `https://search.google.com/local/writereview?placeid=${value.place_id}`
                  : ""
              }
            />
          <Button variant="contained" fullWidth={false} onClick={handlePreview}>
            Next
          </Button>
          </CardContent>
        </Card>
      </Container>
      );
    </div>
  );
};

export default ConnectBiz;
