import Typography from "@mui/material/Typography";

const TopHeader = ({ title, subTitle }) => {
  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
        {title}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: "rgb(179, 172, 172)" }}>
        {subTitle}
      </Typography>
    </>
  );
};

export default TopHeader;
