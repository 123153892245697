import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import { Auth0ProviderWithNavigation } from "./auth0-provider-with-navigation";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigation>
        <App />
      </Auth0ProviderWithNavigation>
    </BrowserRouter>
  </React.StrictMode>
);
