import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
// import LoginButton from "./buttons/LoginButton";
// import SignUpButton from "./buttons/SignUpButton";
// import LogoutButton from "./buttons/LogoutButton";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate("/register");
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ color: "white",   whiteSpace: "nowrap", }}
        >
          Review Genie
        </Typography>

        <List
          sx={{
            display: "flex",
          
          }}
        >
          <ListItem>
            <Link to="/about">
              <ListItemText primary="About" />
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/pricing">
              <ListItemText primary="Pricing" />
            </Link>
          </ListItem>
          <>
            <ListItem>
              <Button
                variant="contained"
                onClick={handleSignUp}
                sx={{
                  whiteSpace: "nowrap",
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                Sign Up
              </Button>
            </ListItem>
            {/* <ListItem>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 2px 20px rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                Login
              </Button>
            </ListItem> */}
          </>

          {/* <ListItem>
            <LogoutButton />
          </ListItem> */}
        </List>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
